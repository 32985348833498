import styled from 'styled-components';

export const Progress = styled.progress`
  width: 100%;

  &::-webkit-progress-bar {
    background-color: ${props => props.theme.color.p100};
  }

  &::-webkit-progress-value {
    background-color: ${props => props.theme.color.p300};
  }
`;
