import { Link } from '@atoms/link';
import { useRedirectOnTimeout } from '@hooks/use-redirect-on-timeout';
import { useTranslation } from '@infra/i18n';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { ButtonRegular as Button } from '@atoms/Button';
import { Grid } from '@atoms/MaterialGrid';

import CollaborationImage from './images/collaboration.svg';
import { Image, Text, Wrapper } from './styled-components';
import { useQueryStringParams } from './useQueryStringParams';

export const REDIRECTION_TIMEOUT = +(
  process.env.GATSBY_QUOTE_FORM_ICON_REDIRECTION_REDIRECT_TIMEOUT || 5000
);

const buildIconRedirectionUrl = ({ location }) => {
  const searchParams = new URLSearchParams(location.search);

  searchParams.set(
    'utm_source',
    process.env.GATSBY_QUOTE_FORM_ICON_REDIRECTION_UTM_SOURCE
  );
  searchParams.set(
    'utm_medium',
    process.env.GATSBY_QUOTE_FORM_ICON_REDIRECTION_UTM_MEDIUM
  );
  searchParams.set(
    'utm_campaign',
    process.env.GATSBY_QUOTE_FORM_ICON_REDIRECTION_UTM_CAMPAIGN
  );

  const redirectUrl = new URL(
    `${process.env.GATSBY_ICON_WEB_URL}${
      process.env.GATSBY_QUOTE_FORM_ICON_REDIRECTION_PATH
    }?${searchParams.toString()}`
  );

  return redirectUrl.toString();
};

export const IconRedirection = props => {
  const { location } = props;

  const redirectUrl = buildIconRedirectionUrl({ location });

  const { t } = useTranslation();

  const {
    mode,
    originCountryName,
    destinationCountryName,
  } = useQueryStringParams({ location });

  useEffect(
    useRedirectOnTimeout({ redirectUrl, timeout: REDIRECTION_TIMEOUT }),
    [redirectUrl]
  );

  return (
    <Grid
      container
      component={Wrapper}
      xsDirection="column-reverse"
      alignItems="center"
      xsjustifyContent="center"
      mdUpjustifyContent="space-between"
    >
      <Grid item container direction="column" mdUpSpacingRight={10} md={8}>
        <Grid item spacingBottom={10}>
          <Text>
            {t('iconRedirectionPage.mainText', {
              mode: t(`modes.${mode}`),
              originCountry: originCountryName,
              destinationCountry: destinationCountryName,
            })}
          </Text>
        </Grid>

        <Grid item>
          <Link
            to={redirectUrl}
            target="_self"
            type="external"
            aria-label={t('iconRedirectionPage.buttonText')}
          >
            <Button size="large">{t('iconRedirectionPage.buttonText')}</Button>
          </Link>
        </Grid>
      </Grid>

      <Grid item md={4} xsSpacingBottom={10}>
        <Image src={CollaborationImage} alt={t('CustomerService')} />
      </Grid>
    </Grid>
  );
};

IconRedirection.propTypes = {
  // gatsby router
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};
