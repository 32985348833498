import { useTimer } from '@hooks/use-timer';
import PropTypes from 'prop-types';
import React from 'react';

import { Progress } from './styled-components';

export const TimedProgress = props => {
  const { timeout, className, ...rest } = props;

  const { value } = useTimer({ timeout });

  return <Progress className={className} max={1} value={value} {...rest} />;
};

TimedProgress.propTypes = {
  timeout: PropTypes.number,
  className: PropTypes.string,
};

TimedProgress.defaultProps = {
  timeout: 5000,
  className: '',
};
