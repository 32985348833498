import { HeaderShipacon } from '@features/headers/header-shipacon';
import { LayoutBase } from '@features/layouts/layout-base';
import { SEO } from '@features/seo';
import { gatsbyPageProps } from '@infra/gatsby/types';
import React from 'react';

import { Grid } from '@atoms/MaterialGrid';

import { IconRedirection, REDIRECTION_TIMEOUT } from './IconRedirection';
import { Container, Main, Progress } from './styled-components';

export const IconRedirectionPage = props => {
  const {
    pageContext: { locale },
    location,
  } = props;

  return (
    <LayoutBase locale={locale}>
      <SEO
        isNoIndex
        isNoFollow
        lang={locale}
        location={location}
        title="iconRedirectionPage.seo.title"
        description="iconRedirectionPage.seo.description"
      />

      <Grid container direction="column" component={Container}>
        <Progress variant="determinate" timeout={REDIRECTION_TIMEOUT} />

        <Grid item spacingTop={8} spacingBottom={8}>
          <HeaderShipacon />
        </Grid>

        <Grid item component={Main} data-testid="LayoutPlain" spacingBottom={8}>
          <IconRedirection location={location} />
        </Grid>
      </Grid>
    </LayoutBase>
  );
};

IconRedirectionPage.propTypes = {
  ...gatsbyPageProps,
};
