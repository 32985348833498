import { COUNTRY_BY_CODE } from '@constants/country';
import {
  DESTINATION_COUNTRY_QUERY_STRING_KEY,
  MODE_QUERY_STRING_KEY,
  ORIGIN_COUNTRY_QUERY_STRING_KEY,
} from '@constants/quote-form';

export const useQueryStringParams = ({ location }) => {
  const searchParams = new URLSearchParams(location.search);

  const mode = searchParams.get(MODE_QUERY_STRING_KEY);
  const originCountryCode = searchParams.get(ORIGIN_COUNTRY_QUERY_STRING_KEY);
  const destinationCountryCode = searchParams.get(
    DESTINATION_COUNTRY_QUERY_STRING_KEY
  );

  const originCountryName = COUNTRY_BY_CODE[originCountryCode]?.name;
  const destinationCountryName = COUNTRY_BY_CODE[destinationCountryCode]?.name;

  return {
    mode,
    originCountryName,
    destinationCountryName,
  };
};
