import { useTranslation } from '@infra/i18n';
import { ROUTES } from '@infra/routes';
import React from 'react';

import { Grid } from '@atoms/MaterialGrid';

import {
  IContainersLogo,
  LogoLink,
  ShipaLogo,
  Wrapper,
} from './styled-components';

const HeaderShipacon = () => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      component={Wrapper}
      justifyContent="space-between"
      alignItems="center"
      xsDirection="column"
    >
      <Grid item xsSpacingBottom={4}>
        <LogoLink to={ROUTES.HOME} aria-label={t('home')} target="_self">
          <ShipaLogo />
        </LogoLink>
      </Grid>

      <Grid item>
        <LogoLink
          target="_self"
          aria-label={t('iconRedirectionPage.iContainersLogo')}
          to={process.env.GATSBY_ICON_WEB_URL}
        >
          <IContainersLogo />
        </LogoLink>
      </Grid>
    </Grid>
  );
};

export { HeaderShipacon };
