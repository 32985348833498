import { Link } from '@atoms/link';
import iContainersLogoSrc from '@images/icontainers-logo.svg';
import LogoIconBase from '@molecules/icons/LogoIcon';
import styled from 'styled-components';

export const Wrapper = styled.header`
  width: 85%;

  margin-left: auto;
  margin-right: auto;
`;

export const LogoLink = styled(Link)`
  display: flex;
`;

export const ShipaLogo = styled(LogoIconBase)`
  width: 170px;
`;

export const IContainersLogo = styled.img.attrs(() => ({
  src: iContainersLogoSrc,
  alt: 'iContainers Logo',
}))`
  width: 170px;
`;
