import createIcon from '@molecules/icons/createIcon';
import React from 'react';

const LogoIcon = createIcon({
  viewBox: '0 0 196 40',
  path: (
    <g id="shipa-freight copy">
      <path
        id="Fill 1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.9023 18.5569V10.1152H41.1563V31.9398H36.9023V22.2549H27.6739V31.9398H23.4199V10.1152H27.6739V18.5569H36.9023Z"
        fill="black"
      />
      <path
        id="Fill 2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.34 31.9404H52.0859V21.1087V10.1143H56.34V31.9404Z"
        fill="black"
      />
      <path
        id="Fill 3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.6222 31.9404H67.3682V10.1143H73.7469C76.5965 10.1143 78.1321 10.5724 79.5064 11.7839C80.9138 13.0271 81.7336 14.9575 81.7336 16.9541C81.7336 21.3709 79.0814 23.8573 74.3693 23.8573H71.6222V31.9404ZM73.3546 20.1608C76.0399 20.1608 77.3479 19.1135 77.3479 16.9541C77.3479 14.7932 76.0053 13.8122 73.0939 13.8122H71.6217V20.1608H73.3546Z"
        fill="black"
      />
      <path
        id="Fill 5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.696 27.3268L90.7325 31.941H86.2178L95.6737 9.65674H98.8804L110.292 37.4352L106.777 40L101.629 27.3268H92.696ZM97.2441 16.037L94.0706 23.8911H100.288L97.2441 16.037Z"
        fill="black"
      />
      <path
        id="Fill 6"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.46434 18.4915C5.91163 17.5421 5.08584 16.9394 5.08584 15.761C5.08584 14.3867 6.50083 13.3213 8.33324 13.3213C9.58549 13.3213 11.1723 13.6905 12.2904 15.0045L13.624 11.8671C11.8685 10.4672 9.81002 9.75439 7.58129 9.75439C3.71907 9.75439 0.773047 12.4382 0.773047 16.0051C0.773047 19.0144 2.14886 20.5529 6.14068 21.9921C7.81034 22.5813 8.66024 22.9746 9.0867 23.2368C9.9366 23.7928 10.3631 24.5779 10.3631 25.4941C10.3631 27.2617 8.9556 28.5697 7.05688 28.5697C4.72418 28.5697 2.60847 26.8564 1.61391 24.9803L0 28.7807C1.8957 31.3259 4.16361 32.4636 7.22114 32.4636C11.6379 32.4636 14.7467 29.5191 14.7467 25.2982C14.7467 21.8293 13.3076 20.2591 8.46434 18.4915Z"
        fill="black"
      />
      <path
        id="Fill 8"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M128.255 23.9924V26.1156H131.253V27.737H128.255V31.9413H126.391V22.3709H131.454V23.9924H128.255Z"
        fill="#F26541"
      />
      <path
        id="Fill 9"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M142.918 31.9404H140.625L138.042 28.0948H137.798V31.9404H135.931V22.37H138.7C140.854 22.37 142.086 23.4037 142.086 25.197C142.086 26.6602 141.313 27.6216 139.907 27.9079L142.918 31.9404ZM138.344 26.7747C139.534 26.7747 140.166 26.2593 140.166 25.2979C140.166 24.3651 139.534 23.8769 138.371 23.8769H137.798V26.7747H138.344Z"
        fill="#F26541"
      />
      <path
        id="Fill 11"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M148.858 23.9924V26.1156H152.158V27.737H148.858V30.3199H152.287V31.9413H146.992V22.3709H152.287V23.9924H148.858Z"
        fill="#F26541"
      />
      <path
        id="Fill 12"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M157.139 31.9409H159.003V22.3705H157.139V31.9409Z"
        fill="#F26541"
      />
      <path
        id="Fill 13"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M173.265 26.8184V27.1198C173.265 30.2044 171.458 32.1559 168.603 32.1559C165.703 32.1559 163.58 30.0764 163.58 27.2057C163.58 24.2792 165.718 22.156 168.66 22.156C170.438 22.156 171.859 22.9306 172.763 24.4073L171.127 25.2829C170.554 24.3365 169.721 23.8633 168.616 23.8633C166.837 23.8633 165.504 25.2979 165.504 27.2057C165.504 29.0712 166.837 30.4636 168.633 30.4636C169.994 30.4636 170.999 29.6589 171.143 28.4398H168.89V26.8184H173.265Z"
        fill="#F26541"
      />
      <path
        id="Fill 15"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M183.748 26.0724V22.3714H185.612V31.9403H183.748V27.6938H179.701V31.9403H177.835V22.3714H179.701V26.0724H183.748Z"
        fill="#F26541"
      />
      <path
        id="Fill 16"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M191.755 31.9404V23.9914H189.617V22.37H195.773V23.9914H193.621V31.9404H191.755Z"
        fill="#F26541"
      />
      <path
        id="Fill 17"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M123.315 1.07292L116.281 17.6309L106.775 39.9996L111.908 37.8778L127.999 0L123.315 1.07292Z"
        fill="#F26541"
      />
    </g>
  ),
});

export default LogoIcon;
