import { TimedProgress } from '@features/timed-progress';
import styled from 'styled-components';

export const Text = styled.div`
  ${props => props.theme.typo.displayL};
  ${props => props.theme.fontWeight.medium};
  color: ${props => props.theme.color.g500};
`;

export const Image = styled.img`
  display: inline-flex;
  width: 100%;
`;

export const Wrapper = styled.div`
  width: 90%;
  max-width: 1200px;
  height: 100%;

  margin: 0 auto;
`;

export const Main = styled.main`
  flex-grow: 1;
`;

export const Container = styled.div`
  height: 100vh;
  flex-wrap: nowrap;
`;

export const Progress = styled(TimedProgress)`
  position: absolute;

  height: 8px;
`;
