import { useEffect, useState } from 'react';

export const useTimer = options => {
  const { timeout = 5000, interval = 100 } = options ?? {};
  const [value, setValue] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setValue(oldValue => {
        const diff = interval / timeout;
        return Math.min(1, oldValue + diff);
      });
    }, interval);

    return () => {
      clearInterval(timer);
    };
  }, [interval, timeout]);

  return { value };
};
